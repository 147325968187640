import React, { InputHTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'
import { UseFormRegisterReturn } from 'react-hook-form'
import { InputLabel } from './InputLabel'

export interface InputBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  label: string
  errorMessage?: string
  hideLabel?: boolean
  register?: UseFormRegisterReturn
  inputClassName?: string
}
export function InputBox({
  id,
  leftIcon,
  rightIcon,
  placeholder = '',
  errorMessage,
  label,
  disabled = false,
  hideLabel = false,
  className,
  required = false,
  register,
  inputClassName,
  ...props
}: InputBoxProps) {
  return (
    <div
      className={cn(
        'flex flex-col justify-start',
        className,
        props.type === 'hidden' && 'hidden'
      )}
    >
      <InputLabel
        htmlFor={id}
        label={label}
        required={required}
        hideLabel={hideLabel}
      />
      <div className="flex items-center">
        {leftIcon && <span className="-mt-1 left-3 relative">{leftIcon}</span>}
        <input
          id={id}
          type="text"
          aria-label={label}
          aria-required={required}
          disabled={disabled}
          placeholder={placeholder}
          className={cn(
            'focus:ring-focus appearance-none inline-flex bg-white h-10 text-primary px-4 ring-2 ring-primary rounded-sm w-full',
            errorMessage && 'ring-error',
            disabled
              ? 'ring-primary-40 text-primary-40'
              : 'hover:ring-primary-60',
            leftIcon && '-ml-4 pl-10',
            rightIcon && '-mr-10 pr-10',
            inputClassName
          )}
          {...register}
          {...props}
        />
        {rightIcon && (
          <span className="-mt-1 -right-2 relative">{rightIcon}</span>
        )}
      </div>
      {errorMessage && (
        <span className="pt-1 text-error text-sm">{errorMessage}</span>
      )}
    </div>
  )
}
