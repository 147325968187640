import { useEffect, useState } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { UseFormRegisterReturn } from 'react-hook-form'
import config from '../../config'

export interface CaptchaProps {
  errorMessage?: string
  register?: UseFormRegisterReturn
  onVerify?: (token: string) => void
}
export function Captcha({ errorMessage, onVerify, register }: CaptchaProps) {
  const PUBLIC_HCAPTCHA_SITEKEY = config.hCaptcha.siteKey
  const [captchaSize, setCaptchaSize] = useState<'compact' | 'normal'>('normal')
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 400) {
        setCaptchaSize('compact')
      } else {
        setCaptchaSize('normal')
      }
    }
    window.addEventListener('resize', handleResize)
  })
  return (
    <span className="text-error text-sm">
      <HCaptcha
        sitekey={PUBLIC_HCAPTCHA_SITEKEY}
        onVerify={onVerify}
        size={captchaSize}
        {...register}
      />

      {!!errorMessage && errorMessage}
    </span>
  )
}
