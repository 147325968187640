import { LabelHTMLAttributes } from 'react'
import cn from 'classnames'

export interface InputLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  label: string
  hideLabel?: boolean
  required?: boolean
}
export function InputLabel({
  required = false,
  label,
  hideLabel = false,
  ...props
}: InputLabelProps) {
  return (
    <label
      className={cn(
        'font-semibold pb-1 text-primary-text',
        hideLabel && 'sr-only'
      )}
      {...props}
    >
      {label}
      {required && <span className="text-error">*</span>}
    </label>
  )
}
