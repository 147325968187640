import React, { ReactNode, TextareaHTMLAttributes } from 'react'
import cn from 'classnames'
import { InputLabel } from './InputLabel'

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  label: string
  errorMessage?: string
  disabled?: boolean
  hideLabel?: boolean
  register?: object
}
export function TextArea({
  id,
  placeholder = '',
  errorMessage,
  label,
  disabled = false,
  hideLabel = false,
  required = false,
  className,
  register,
  ...props
}: TextAreaProps) {
  return (
    <div className={cn('flex flex-col justify-start', className)}>
      <InputLabel
        htmlFor={id}
        label={label}
        required={required}
        hideLabel={hideLabel}
      />
      <div className="flex items-center">
        <textarea
          id={id}
          aria-label={label}
          aria-required={required}
          disabled={disabled}
          placeholder={placeholder}
          className={cn(
            'focus:ring-focus appearance-none inline-flex bg-none text-primary px-4 py-2 ring-2 ring-primary rounded-sm w-full',
            errorMessage && 'ring-error',
            disabled
              ? 'ring-primary-40 text-primary-40'
              : 'hover:ring-primary-60'
          )}
          {...register}
          {...props}
        />
      </div>
      {errorMessage && (
        <span className="pt-1 text-error text-sm">{errorMessage}</span>
      )}
    </div>
  )
}
